define(['app', 'accessibilityFocusHelper', 'uniqueIDHelper'], function(app, accessibilityFocusHelper, uniqueIDHelper) {

  const productFrequentlyBoughtTogether = function() {
    const component = {};

    const _config = {
      selectors: {
        element: '[data-component=productFrequentlyBoughtTogether]',
        innerContainer: '.frequentlyBoughtTogether_innerContainer',
        accordionTarget: '.productFrequentlyBoughtTogether_accordionTarget',
        accordionFooter: '.productFrequentlyBoughtTogether_accordionFooter',
        accordionToggleButton: '.productFrequentlyBoughtTogether_accordionToggleButton',
        variationDropdowns: '.productFrequentlyBoughtTogether_dropdown',
        recTiles: '[data-rec-id]',
        errorMessages: '.productFrequentlyBoughtTogether_dropdownError',
        addToBasket: '.productFrequentlyBoughtTogether_addToBasketButton',
        dropdownSegment: '.productFrequentlyBoughtTogether_dropdownSegment'
      },
      attributes: {
        productId: 'data-product-id',
        recId: 'data-rec-id',
        variationId: 'data-variation-id'
      },
      classes: {
        dropdownError: 'productFrequentlyBoughtTogether_error',
        accordionTargetExpanded: 'productFrequentlyBoughtTogether_accordionTarget-expanded',
        accordionFooterExpanded: 'productFrequentlyBoughtTogether_accordionFooter-expanded',
        showErrorMessage: 'show'
      },
      subscribeChannels: {
        productFrequentlyBoughtTogether: 'productFrequentlyBoughtTogether/update'
      },
      isClosed: true
    };

    const _init = function (element, userDriven) {
      component.element = element;

      component.accordionFooter = element.querySelector(component.config.selectors.accordionFooter);
      component.accordionTarget = element.querySelector(component.config.selectors.accordionTarget);
      component.accordionToggleButton = element.querySelector(component.config.selectors.accordionToggleButton);
      component.addToBasket = element.querySelectorAll(component.config.selectors.addToBasket);
      component.variationDropdowns =
        element.querySelectorAll(component.config.selectors.variationDropdowns);
      component.errorMessages = element.querySelectorAll(component.config.selectors.errorMessages);

      component.productId = element.getAttribute(component.config.attributes.productId);

      if (!userDriven) {
        app.subscribe(component.config.subscribeChannels.productFrequentlyBoughtTogether, component.updateFbt);
      }

      component.bindListeners();

      return component;
    };

    const _toggle = function() {
      if (component.config.isClosed) {
        component.openAccordion();
      } else {
        component.closeAccordion();
      }
      component.config.isClosed = !component.config.isClosed;
    };

    const _openAccordion = function() {
      component.accordionTarget.classList.add(component.config.classes.accordionTargetExpanded);
      component.accordionFooter.classList.add(component.config.classes.accordionFooterExpanded);
      component.accordionToggleButton.setAttribute('aria-expanded', 'true');
      accessibilityFocusHelper.focusAndScroll(component.accordionTarget);
    };

    const _closeAccordion = function() {
      component.accordionTarget.classList.remove(component.config.classes.accordionTargetExpanded);
      component.accordionFooter.classList.remove(component.config.classes.accordionFooterExpanded);
      component.accordionToggleButton.setAttribute('aria-expanded', 'false');
    };

    const _validateData = function() {
      let valid = true;

      const recTiles = component.element.querySelectorAll(component.config.selectors.recTiles);
      Array.from(recTiles).filter(tile => tile.offsetHeight !== 0).forEach(tile => {
          const dropdownSegments = tile.querySelectorAll(component.config.selectors.dropdownSegment);
          Array.from(dropdownSegments).forEach(segment => {
            const dropdown = segment.querySelector(component.config.selectors.variationDropdowns);
            if (dropdown.value === '') {
              valid = false;

              dropdown.classList.add(component.config.classes.dropdownError);

              const errorMessage = segment.querySelector(component.config.selectors.errorMessages);
              errorMessage.classList.add(component.config.classes.showErrorMessage);
            }
          });
      });

      return valid;
    };

    const _updateVariations = function() {
      app.ajax.post({
                      url: `/${component.productId}/fbt.variations`,
                      send: JSON.stringify(component.constructPostData()),
                      requestHeader: {
                        header: 'Content-Type',
                        value: 'application/json'
                      },
                      success: component.variationSuccessHandler,
                      error: component.variationErrorHandler
                    });
    };

    const _constructPostData = function() {
      const postData = {};

      const recTiles = component.element.querySelectorAll(component.config.selectors.recTiles);
      Array.from(recTiles).filter(tile => tile.offsetHeight !== 0).forEach(tile => {

          const recommendationId = tile.getAttribute(component.config.attributes.recId);
          postData[recommendationId] = { variations: {} };
          postData[recommendationId].isRequestedProduct = recommendationId === component.productId;

          const inputs = tile.querySelectorAll(component.config.selectors.variationDropdowns);
          Array.from(inputs).forEach(input => {
            const variation = parseInt(input.getAttribute(component.config.attributes.variationId));
            if (input.value) {
              postData[recommendationId]['variations'][variation] = parseInt(input.value);
            }
          });
      });

      return postData;
    };

    const _variationSuccessHandler = function(res) {
      component.element.outerHTML = res;
      const element = document.querySelector(component.config.selectors.element);
      component.init(element);

      if (!component.config.isClosed) {
        component.openAccordion();
      }
    };

    const _variationErrorHandler = function() {
      $console.error("ERROR: Could not update product data for selected variation");
    };

    const _addToBasketClick = function(event) {
      const dataValid = component.validateData();

      if (!dataValid) {
        event.preventDefault();
        event.returnValue = false;
      }
    };

    const _bindListeners = function(event) {
      component.accordionToggleButton.addEventListener('click', component.toggle);
      Array.from(component.variationDropdowns).forEach(
        dropdown => dropdown.addEventListener('change', () => {
          component.updateVariations()
        }));

      Array.from(component.addToBasket).forEach(btn => btn.addEventListener('click', component.addToBasketClick));
    };

    const _updateFbt = (childId) => {
      return new Promise(() => app.ajax.get({
        url: '/' + childId + '.fbtupdate',
        success: component.fbtSuccessHandler,
        error: component.fbtErrorHandler,
      }));
    };

    const _fbtSuccessHandler = (response) => {
      const parent = component.element.parentNode;
      parent.innerHTML = response;
      const newElement = parent.querySelector('[data-component=productFrequentlyBoughtTogether]');
      component.init(newElement, true);
    };

    const _fbtErrorHandler = function _errorHandler() {
      console.error('ERROR: Could not retrieve new frequently bought products data');
    };

    component.config = _config;
    component.init = _init;
    component.openAccordion = _openAccordion;
    component.closeAccordion = _closeAccordion;
    component.toggle = _toggle;
    component.validateData = _validateData;
    component.updateVariations = _updateVariations;
    component.constructPostData = _constructPostData;
    component.variationSuccessHandler = _variationSuccessHandler;
    component.variationErrorHandler = _variationErrorHandler;
    component.addToBasketClick = _addToBasketClick;
    component.bindListeners = _bindListeners;
    component.updateFbt = _updateFbt;
    component.fbtSuccessHandler = _fbtSuccessHandler;
    component.fbtErrorHandler = _fbtErrorHandler;

    return component;
  };

  return productFrequentlyBoughtTogether;
});



